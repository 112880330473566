dmx.Component('preloader', {

    constructor: function(node, parent) {
        this.docLoaded = false;
        this.imgLoaded = false;
        this.dataLoaded = false;
        this.loaded = false;

        dmx.BaseComponent.call(this, node, parent);
    },

    attributes: {
        preview: {
            type: Boolean,
            default: false
        },

        color: {
            type: String,
            default: '#333'
        },

        bgcolor: {
            type: String,
            default: '#fff'
        },

        size: {
            type: Number,
            default: 60
        },

        spinner: {
            type: String,
            default: null
        }
    },

    methods: {
        show: function() {
            this.show();
        },

        hide: function() {
            this.hide();
        }
    },

    event: {},

    spinners: {
        rotatingPlane: 0,
        doubleBounce: 2,
        wave: 5,
        wanderingCubes: 2,
        pulse: 0,
        chasingDots: 2,
        threeBounce: 3,
        circle: 12,
        cubeGrid: 9,
        fadingCircle: 12,
        foldingCube: 4
    },

    render: function(node) {
        this.$node.classList.add('dmxPreloader');

        this.$node.style.setProperty('--color', this.props.color);
        this.$node.style.setProperty('--bgcolor', this.props.bgcolor);
        this.$node.style.setProperty('--size', this.props.size + 'px');

        if (this.props.spinner && this.spinners.hasOwnProperty(this.props.spinner)) {
            var template = '<div class="dmxPreloader-spinner dmxPreloader-' + this.props.spinner + '">'
            for (var i = 0; i < this.spinners[this.props.spinner]; i++) {
                template += '<div></div>';
            }
            template += '</div>';

            this.$node.innerHTML = template;
        }

        this.show();

        window.addEventListener('load', function() {
            dmx.requestUpdate();
        });
    },

    update: function(props) {
        if (this.loaded || this.props.preview) return;

        if (JSON.stringify(props) != JSON.stringify(this.props)) {
            this.$node.style.setProperty('--color', this.props.color);
            this.$node.style.setProperty('--bgcolor', this.props.bgcolor);
            this.$node.style.setProperty('--size', this.props.size + 'px');
        }

        this.checkDocLoaded();
        this.checkImgLoaded();
        this.checkDataLoaded();

        if (this.docLoaded && this.imgLoaded && this.dataLoaded) {
            requestAnimationFrame(function() {
                if (!this.loaded) {
                    // extra check for when extra content/images are added (repeater)
                    this.checkDocLoaded();
                    this.checkImgLoaded();
                    this.checkDataLoaded();

                    if (this.docLoaded && this.imgLoaded && this.dataLoaded) {
                        this.hide();
                        this.loaded = true;
                    }
                }
            }.bind(this));
        }
    },

    show: function() {
        // show preloader
        this.$node.style.removeProperty('opacity');
        this.$node.style.removeProperty('z-index');
        document.body.style.setProperty('overflow', 'hidden');
    },

    hide: function() {
        // hide preloader (use of opacity for fade effect)
        this.$node.style.setProperty('opacity', 0);
        this.$node.style.setProperty('z-index', -1);
        document.body.style.removeProperty('overflow');
    },

    checkDocLoaded: function() {
        this.docLoaded = document.readyState === 'complete';
    },

    checkImgLoaded: function() {
        this.imgLoaded = dmx.array(document.getElementsByTagName('IMG')).every(function(img) {
            if (!img.hasAttribute('src') && img.hasAttribute('dmx-bind:src')) {
                img.isLoaded = false;
            }

            if (img.isLoaded === true) {
                return true;
            }

            if (img.completed && img.naturalHeight !== 0) {
                return true;
            }

            if (!img.isListening) {
                var image = new Image();
                image.addEventListener('load', function() { img.isLoaded = true; dmx.requestUpdate(); });
                image.addEventListener('error', function() { img.isLoaded = true; dmx.requestUpdate(); });
                image.src = img.src;
                img.isListening = true;
            }

            return img.isLoaded;
        }, this);
    },

    checkDataLoaded: function() {
        this.dataLoaded = this.getDatasets().every(function(data) { return data.data != null });
    },

    getDatasets: function() {
        var datasets = [];

        dmx.app.children.forEach(function _getDatasets(child) {
            if (child.data.$type == 'serverconnect' && !child.props.noload) {
                datasets.push(child.data);
            }

            child.children.forEach(_getDatasets);
        });

        return datasets;
    }

});
